<template>
  <div>
    <header>
      <img alt="CMC logo" src="@/assets/images/logo-clearmortgagecapital.svg">
    </header>

    <main>
      <!--   Section for Tablets and Full Screens   -->
      <section class="d-none d-md-block p-sm-normal">
        <div class="row">
          <div class="col-md-12 col-lg-8">
            <div class="col-block bg-white">
              <!--  Col Header  -->
              <div class="col-header w-100 border-bottom-light d-flex justify-content-between align-items-center">
                <div class="col-header-title">Credit Report Authorization</div>
                <span>Page 01/01</span>
              </div>

              <!--  Docs preview  -->
              <vue-html2pdf
                ref="html2Pdf"
                :show-layout="false"
                :enable-download="true"
                :float-layout="false"
                :manual-pagination="false"
                :paginate-elements-by-height="1400"
                :pdf-quality="2"
                :preview-modal="false"
                :html-to-pdf-options="options">
                <section slot="pdf-content">
                  <div id="pdf-generate">
                    <credit-auth-preview :docsData="docData"/>
                  </div>
                </section>
              </vue-html2pdf>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 fixed-block">
            <div class="col-block bg-white">
              <!--  Col Header  -->
              <div class="col-header w-100 border-bottom-light d-flex justify-content-between align-items-center">
                <div class="col-header-title">Sign Document</div>
              </div>

              <!--  Show if docs sign was saved  -->
              <div v-if="docData.status === 'signed'" class="col-body signing-complete">
                <img alt="Signing done" src="@/assets/icons/icon-normal-done.svg">
                <div class="signing-complete-title">Document Submitted</div>
                <div class="signing-complete-subtitle">
                  Thanks for authorizing and submitting your document. Our Loan Officer will review it and contact you
                  asap.
                </div>
                <button class="btn btn-outline-grey" @click="downloadDoc">
                  Download your Copy (PDF)
                </button>
              </div>

              <!--  Show if docs sign wasn't saved  -->
              <div v-else class="col-body">
                <div class="form-group fullName-field">
                  <label for="fullNameField">Type your full name</label>
                  <input id="fullNameField" v-model="docData.signature"  @keypress="isLetter($event)" class="form-control" type="text">
                </div>
                <div class="form-group signature-preview">
                  <label for="signatureField">Your Signature (Preview)</label>
                  <p id="signatureField">{{ docData.signature }}</p>
                </div>
                <div class="form-check">
                  <input id="legalRepresentationField" v-model="info.legalRepresentation" class="form-check-input"
                         type="checkbox">
                  <label class="form-check-label" for="legalRepresentationField">
                    I agree this will be used as a legal representation of my signature.
                  </label>
                </div>
                <div class="form-check">
                  <input id="aboveDocumentField" v-model="info.aboveDocument" class="form-check-input" type="checkbox">
                  <label class="form-check-label" for="aboveDocumentField">
                    I’ve read and agree to the above document.
                  </label>
                </div>
                <base-button title="Authorize & Submit" :disabled="!signingComplete"
                             :loading="ui.saving" @click-btn="acceptSignature" action="secondary-default" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--   Section for mobile   -->
      <section class="d-block d-md-none">
        <div v-if="docData.status === 'pending'">
          <div v-if="!ui.signing && !ui.saved">
            <!--    Page Header for mobile    -->
            <div class="page-header d-flex justify-content-between align-items-center">
              <h3 class="page-header-title">Read Document before you sign</h3>
            </div>

            <!--    Page Content for mobile     -->
            <div class="page-content ps-0 pe-0 pb-5">
              <credit-auth-mobile-preview :docsData="docData" @editSignature="editSign"/>

              <div class="col-12 mt-3 mobile-agree">
                <div v-if="!info.signed">
                  <button class="btn btn-primary agree-btn" @click="editSign">
                    Sign Document
                  </button>
                </div>
                <div v-else>
                  <div class="form-check">
                    <input id="aboveDocumentFieldMobile" v-model="info.aboveDocument" class="form-check-input"
                           type="checkbox">
                    <label class="form-check-label" for="aboveDocumentFieldMobile">
                      I’ve read and agree to the above document.
                    </label>
                  </div>
                  <button class="btn btn-primary agree-btn" :class="{disabled: !info.aboveDocument}"
                          @click="acceptSignature">
                  <span v-if="ui.saving" aria-hidden="true"
                        class="spinner-border spinner-border-sm" role="status"></span>
                    <span v-else>Authorize & Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="ui.signing && !ui.saved">
            <!--    Page Header for mobile    -->
            <div class="page-header d-flex align-items-center" @click="withoutSigning">
              <button class="btn btn-control me-2">
                <img src="@/assets/icons/bold-icon previous.svg" alt="Return back">
              </button>
              <h3 class="page-header-title m-0">
                Sign Document
              </h3>
            </div>

            <!--     Page Content     -->
            <div class="page-content bg-grey">
              <div class="form-group fullName-field mt-3">
                <label for="fullNameFieldMobile">Type your full name</label>
                <input id="fullNameFieldMobile" v-model="docData.signature" @keypress="isLetter($event)" class="form-control" type="text">
              </div>
              <div class="form-group signature-preview">
                <label for="signatureFieldMobile">Your Signature (Preview)</label>
                <p id="signatureFieldMobile">{{ docData.signature }}</p>
              </div>
              <div class="form-check">
                <input id="legalRepresentationFieldMobile" v-model="info.legalRepresentation" class="form-check-input"
                       type="checkbox">
                <label class="form-check-label" for="legalRepresentationFieldMobile">
                  I agree this will be used as a legal representation of my signature.
                </label>
              </div>
              <div class="insert-signature">
                <button class="btn agree-btn" :class="{disabled: !info.legalRepresentation}" @click="saveSignature">
                  Insert Signature
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Show if docs sign was saved  -->
        <div v-else class="col-body signing-complete bg-white">
          <img alt="Signing done" src="@/assets/icons/icon-normal-done.svg">
          <div class="signing-complete-title">
            Document Submitted
          </div>
          <div class="signing-complete-subtitle">
            Thanks for authorizing and submitting your document. Our Loan Officer will review it and contact you
            asap.
          </div>
          <button class="btn btn-outline-grey" @click="downloadDoc">
            Download your Copy (PDF)
          </button>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import CreditAuthPreview from "./preview/CreditAuthPreview";
import CreditAuthMobilePreview from "./preview/CreditAuthMobilePreview";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: 'CreditReportSubmitDocument',
  components: {CreditAuthMobilePreview, CreditAuthPreview, VueHtml2pdf, BaseButton},
  data() {
    return {
      info: {
        legalRepresentation: false,
        aboveDocument: false,
        signed: false
      },
      docData: {
        status: null,
        requestSentOn: null,
        documentSignedOn: null,
        signature: null,
        firstName: null,
        lastName: null,
        email: null,
        address: null,
        city: null,
        state: null,
        zip: null,
      },
      ui: {
        loading: false,
        saving: false,
        saved: false,
        signing: false
      },
      signingBackup: '',
      options: {
        margin: 0,
        filename: 'cmc_e_sign.pdf',
        enableLinks: true,
        jsPDF: {
          format: 'letter'
        }
      }
    }
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    getData() {
      this.ui.loading = true
      this.$http.get(`/api/v1/credit-auth/details/${this.$route.params.uuid}`)
        .then(response => {
          this.docData = response.data.doc
          this.ui.loading = false
        })
        .catch()
    },
    acceptSignature() {
      this.ui.saving = true
      this.$http.post(`/api/v1/credit-auth/accept-signature/${this.$route.params.uuid}`, {
        sign: this.docData.signature
      })
        .then(response => {
          this.ui.saving = false
          this.ui.saved = true
          this.docData = response.data.doc
        })
        .catch()
    },
    downloadDoc() {
      this.$refs.html2Pdf.generatePdf()
    },
    editSign() {
      this.ui.signing = true
      this.signingBackup = this.docData.signature
    },
    withoutSigning() {
      this.ui.signing = false
      this.docData.signature = this.signingBackup
    },
    saveSignature() {
      this.ui.signing = false
      this.info.signed = true
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  },
  computed: {
    signingComplete: function () {
      return this.info.legalRepresentation === true && this.info.aboveDocument === true && this.docData.signature.length > 0;
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  background: white;
  text-align: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 768px) {
    position: fixed;
    width: 100%;
  }

  @media screen and (max-width: 568px) {
    justify-content: end;
    padding: 0 12px;
    margin-bottom: 10px;
  }

  img {
    max-width: 180px;
    width: 100%;
    height: 48px;
  }
}

body {
  background-color: #F6F6F6;
}

main {
  background-color: #F6F6F6;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
  .p-sm-normal {
    padding: 30px;
  }

  .page-content {
    background: white;
    margin-bottom: 40px;

    &.bg-grey {
      background-color: #F6F6F6;
      height: 100vh;
      margin-bottom: 0;
    }
  }

  .col-block {
    margin: 0 10px;
    height: fit-content;

    .col-header {
      padding: 20px 30px;
      border-bottom: 1px solid #DADADA;

      span {
        color: rgba(0, 0, 0, .7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
      }

      &-title {
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
  }

  .agree-btn {
    width: 100%;
    color: #FFFFFF;
    height: 48px;
    max-height: 48px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-weight: normal;
    background: linear-gradient(180deg, #065AE5 0%, #0552D1 100%);
    box-shadow: inset 0 -2px 0 0 #054BBD, inset 0 2px 1px 0 #1068F9;
  }

  .page-header {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 13px 16px;

    &-title {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .mobile-agree {
    position: fixed;
    bottom: 0;
    background: white;
    padding: 30px 24px;
    @media screen and (max-width: 568px) {
      button {
        margin: 0;
      }
    }
  }

  .fullName-field {
    max-width: 100%;
    margin-bottom: 24px;

    label {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 12px;
    }

    input {
      background-color: #FFFFFF;
      border-radius: 0;

      &::placeholder {
        color: rgba(0, 0, 0, .46);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

  }

  .form-check {
    align-items: normal;
    margin-bottom: 18px;

    label {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      @media screen and (max-width: 568px) {
        display: flex;
        align-items: center;
        color: #000000;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 21px;
      }
    }

    input {
      max-width: 18px;
      width: 18px;
      height: 18px;

      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }

  .insert-signature {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 16px 30px 16px;
  }

  .signature-preview {
    p {
      color: #000000;
      font-family: "Safiar Signature";
      font-size: 42px;
      letter-spacing: 0;
      line-height: 36px;
      margin: 18px 0 30px 0;
    }
  }

  .col-body {
    padding: 30px;

    &.bg-white {
      background: white;
      height: 100vh;
    }

    &.signing-complete {
      text-align: center;

      .signing-complete-title {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 24px;
      }

      .signing-complete-subtitle {
        color: rgba(0, 0, 0, .7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 12px;
        padding: 0 38px;
        margin-bottom: 36px;
      }
    }

    .btn-outline-grey {
      width: 100%;
      height: 48px;
      max-height: 48px;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      border: 1px solid rgba(0, 0, 0, .3);
      border-radius: 8px;
    }
  }

  .fixed-block {
    @media screen and (min-width: 1024px) {
      position: fixed;
      right: 0;
    }
  }
}

#pdf-generate {
  max-width: 791px;
  width: 100%;
  height: 1000px;
}

.vue-html2pdf::v-deep {
  .content-wrapper {
    margin: 30px auto;

  }
}
</style>
